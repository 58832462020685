<template>
  <div class="menu-container">
    <div class="menu-heading">
      Assistants
    </div>

    <ul class="menu-item-container">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {}
};
</script>

<style>
.menu-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu-heading {
  width: 100%;
  text-align: center;
  font-size: 22px;
  padding-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #3f3f41;
}

@media (max-width: 800px) {
  .menu-heading {
    padding-top: 16px;
  }
}

.menu-item-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 800px) {
  .menu-item-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.router-link-active {

}
</style>
