<template>
  <div class="chat-form">
    <div class="chat-form-input-container">
      <div class="chat-form-inner">
        <textarea class="chat-form-input"
                  placeholder="Type your request to assistant..."
                  v-on:keypress.enter.prevent="onSubmit"
                  v-model="input"
        ></textarea>
        <button disabled="" class="chat-form-submit">
          <span data-state="closed" @click="onSubmit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" class="chat-form-submit-icon" stroke-width="2"><path d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z" fill="currentColor"></path></svg>
          </span>
        </button>
      </div>

      <div class="chat-form-input-footer">
        <span @click="onClear" class="chat-form-input-footer-clear">clear</span>
        <div class="chat-form-input-footer-tip">Use enter to submit</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ChatForm',
  data: function() {
    return {
      input: '',
    }
  },
  props: [],
  components: {
  },
  methods: {
    onSubmit: function() {
      const value = this.input;
      this.$emit('submit', value);
      this.input = '';
    },
    onClear: function() {
      this.$emit('clear');
    }
  }
}
</script>

<style>

.chat-form {
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 100%;
  justify-content: center;

  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
}

.chat-form-inner {
  height: 56px;
  -webkit-font-smoothing: antialiased;
  background-color: rgb(255, 255, 255);
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  color-scheme: light;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-size: 16px;
  font-style: normal;
  font-variant-caps: normal;
  font-variation-settings: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variation-settings: normal;
  line-height: 24px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-top: 8px;
  position: relative;
  tab-size: 1;
}

.chat-form-input {
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgb(142, 142, 160);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(142, 142, 160);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgb(142, 142, 160);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgb(142, 142, 160);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  color-scheme: light;
  cursor: auto;
  display: block;
  flex-direction: column;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-size: 16px;
  font-style: normal;
  font-variant-caps: normal;
  font-variation-settings: normal;
  font-weight: 400;
  height: 24px;
  letter-spacing: normal;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  max-height: 200px;
  overflow-wrap: break-word;
  overflow-y: hidden;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 48px;
  padding-top: 0px;
  resize: none;
  tab-size: 4;
  text-align: start;
  text-indent: 0px;
  text-shadow: none;
  text-transform: none;
  white-space: pre-wrap;
  width: 750px;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
}

.chat-form-submit {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 8px;
  width: 32px;
  height: 32px;
  align-items: flex-start;
  appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-color: rgb(217, 217, 227);
  border-style: solid;
  border-width: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  color: rgb(172, 172, 190);
  color-scheme: light;
  cursor: default;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  opacity: 0.4;
  transition-duration: 0.15s;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-font-smoothing: antialiased;
}

.chat-form-submit-icon {
  width: 16px;
  height: 16px;
  color: rgb(172, 172, 190);
  color-scheme: light;
  cursor: pointer;
  display: block;
  fill: none;
  letter-spacing: normal;
  line-height: 24px;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 4px;
  stroke-width: 2px;
  vertical-align: middle;
}

.chat-form-input-container {
  width: 90%;
  max-width: 540px;
  height: 60px;
}

.chat-form-input {
  width: 100%;
  height: 100%;

  padding: 7px 10px;

  border-radius: 10px;
  border-color: #e9eaef;

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;

  font-size: 14px;
}

.chat-form-input-footer {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  padding-left: 25px;
  padding-right: 15px;
  font-size: 12px;
  color: #74747a;
}

.chat-form-input-footer-clear {
  display: flex;
  justify-content: start;
  text-align: left;
  cursor: pointer;
}

.chat-form-input-footer-tip {
  display: flex;
  justify-content: end;
  width: 100%;
  text-align: right;
}

</style>