<template>
  <div class="menu-item">
    <router-link class="menu-item-link" :to="{name: 'assistant', params: {role: role}}">{{name}}</router-link>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: ["role", "name"]
};
</script>

<style>
.menu-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: flex-start;
  border-radius: 5px;
  padding-left: 10px;
  padding-bottom: .75rem;
  padding-top: .75rem;
}
.menu-item:hover {
  border-color: rgb(70,72,75);
  background-color: rgb(60,62,65);
}
.menu-item-link {
  text-align: left;
  color: rgb(186, 186, 191);
  text-decoration: none;
}

.menu-item-link:hover {
  color: rgb(236, 236, 241);
  text-decoration: none;
}

.router-link-active {
  color: rgb(236, 236, 241);
}

</style>
