<template>
  <div id="nav">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: {}
};
</script>

<style>
#nav {
  display: flex;
  flex-direction: row;
  width: 260px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgba(32,33,35);
  overflow-x: hidden;
  color: rgb(236, 236, 241);
}

@media (max-width: 800px) {
  #nav {
    width: 100%;
    height: 230px;
    overflow-y: hidden;
  }
}

</style>
