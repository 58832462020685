<template>
  <div class="chat" v-if="isValidRole">
    <ChatHeading :role="assistant"></ChatHeading>
    <ChatMessages :chat="chat"></ChatMessages>
    <ChatForm @submit="onSubmit" @clear="onClear"></ChatForm>
  </div>

  <div class="chat-error" v-if="!isValidRole">
    Selected role is not supported.
  </div>
</template>

<script>
// @ is an alias to /src
import ChatHeading from "@/components/chat/ChatHeading";
import ChatMessages from "@/components/chat/ChatMessages";
import ChatForm from "@/components/chat/ChatForm";
import { store } from '@/store'
import { Role, UserRole, Message, assistantRoles } from '@/models';

export default {
  name: 'Assistant',
  props: {
  },
  data() {
    return {
      assistant: Role,
    }
  },
  created() {
    this.assistant = assistantRoles[this.$route.params.role];
  },
  beforeRouteUpdate (to, _, next) {
    this.assistant = assistantRoles[to.params.role];
    next();
  },
  components: {
    ChatHeading,
    ChatMessages,
    ChatForm,
  },
  computed: {
    chat() {
      return this.assistant.code;
    },
    isValidRole() {
      return !!this.assistant;
    },
    messages() {
      return store.getters.getChatMessages(this.chat);
    }
  },
  methods: {
    onSubmit: function(txt) {
      const msg = new Message(UserRole, txt);
      store.commit('addMessage', {
        chat: this.chat,
        message: msg,
      })

      store.dispatch('requestCompletion', {
        chat: this.chat,
        assistant: this.assistant.code,
      });
    },
    onClear: function() {
      store.commit('clearChat', {
        chat: this.chat,
      });
    },
  },
}
</script>

<style>
.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

</style>