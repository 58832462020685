<template>
  <div class="chat-heading">
    <div class="chat-heading-title">You're chatting with {{role.name}}</div>
    <div class="chat-heading-description">{{role.description}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Role } from '@/models';

export default {
  name: 'ChatHeading',
  props: {
    role: Role,
  },
  components: {
  }
}
</script>

<style>
.chat-heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
}

.chat-heading-title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 24px;
}

.chat-heading-description {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>