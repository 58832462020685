<template>
  <div class="chat-messages" ref="chatBox">
    <div class="chat-messages-message"
         v-for="(message, index) in messages"
         :key="index"
         ref="messageContainer"
         :class="{
           'chat-messages-message-user': message.role.code === 'user',
           'chat-messages-message-assistant': message.role.code !== 'user'
         }"
    >
      <div class="chat-messages-message-container">
        <div class="chat-messages-message-icon">
          <img :src="iconURL(message)" :alt="message.role.name" width="40" height="40">
        </div>
        <div class="chat-messages-message-content">
          <img class="chat-messages-message-gif" v-if="message.gif" :src="message.gif">
          <div class="chat-messages-message-text">{{ message.text }}</div>
        </div>
      </div>
    </div>
    <div class="chat-messages-message" v-if="isWaitingForCompletion">
      <Loader></Loader>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { store } from '@/store'
import Loader from '@/components/Loader'

export default {
  name: 'ChatMessages',
  props: {
    chat: String,
  },
  components: {
    Loader,
  },
  updated() {
    this.scrollToBottom();
  },
  computed: {
    isWaitingForCompletion(){
      return store.getters.isWaitingForCompletion(this.chat);
    },
    messages() {
      return store.getters.getChatMessages(this.chat);
    },
  },
  methods: {
    iconURL(message) {
      return require('@/assets/' + message.role.icon);
    },
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      if (!container) {
        return;
      }

      const lastElement = container.pop();
      if (!lastElement) {
        return;
      }

      lastElement.scrollIntoView();
    },
  },
}

</script>

<style>
.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 24px;
}

.chat-messages-message {
  display: flex;
  justify-content: center;
  width: 100%;
}

.chat-messages-message-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1024px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.chat-messages-message-assistant {
  background-color: #e9eaef;
}

.chat-messages-message-user {}

.chat-messages-message-icon {
  margin-left: 10px;
  width: 54px;
}

.chat-messages-message-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-messages-message-gif {
  max-height: 220px;
  max-width: 220px;
}

.chat-messages-message-text {
  margin-top: 12px;
}

</style>