<template>
  <div id="main" class="content pure-g">
    <Navigation>
      <Menu>
        <MenuItem role="big-joe" name="Big Joe"></MenuItem>
        <MenuItem role="granny-liz" name="Granny Liz"></MenuItem>
        <MenuItem role="cynologist" name="Tyler"></MenuItem>
      </Menu>
    </Navigation>

    <div id="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navigation from "./components/menu/Navigation";
import Menu from "./components/menu/Menu";
import MenuItem from "./components/menu/MenuItem";

export default {
  name: "App",
  components: {
    Navigation,
    Menu,
    MenuItem,
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f9fc;
  color: #000;
  overflow: hidden;
}

#main {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: nowrap;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 800px) {
  #main {
    flex-direction: column;
  }
}

#content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
</style>
