import {createStore} from 'vuex'
import {Message} from "@/models";
import {assistantRoles} from "@/models";

const CompletionURL = 'https://dog-completion.cheshir.me/complete';

class Chat {
    isWaitingForCompletion = false;
    messages = [];
}

export const store = createStore({
    state() {
        return {
            chats: {},
        }
    },
    actions: {
        async requestCompletion({ commit, state }, options) {
            if (!state.chats[options.chat]) {
                throw new Error('Failed to request completion for unknown chat: ' + options.chat)
            }

            commit('waitForCompletion', {chat: options.chat})

            const chat = state.chats[options.chat]
            let messages = [];
            chat.messages.forEach((message) => {
                messages.push({
                    role: message.role.code,
                    text: message.text,
                })
            })

            await fetch(CompletionURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                body: JSON.stringify({
                    assistant: options.assistant,
                    messages: messages,
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (!response.message) {
                        throw new Error("Returned empty completion")
                    }

                    const role = assistantRoles[response.message.role];
                    if (!role) {
                        throw new Error("Got completion from unknown role: " + response.message.role)
                    }

                    commit('addMessage', {
                        chat: options.chat,
                        message: new Message(
                            role,
                            response.message.text,
                            response.message.gif
                        ),
                    })
                })
                .catch(console.error)

            commit('confirmCompletion', {chat: options.chat})
        },
    },
    mutations: {
        addMessage(state, options) {
            if (!options.chat) {
                throw new Error('Failed to add message to unknown chat');
            }

            if (!state.chats[options.chat]) {
                state.chats[options.chat] = new Chat();
            }

            const chat = state.chats[options.chat];

            if (!chat.messages) {
                chat.messages = [];
            }

            chat.messages.push(options.message)
        },
        clearChat(state, options) {
            const chat = state.chats[options.chat];
            if (!chat) {
                console.error(`Failed to clear unknown chat '${chat}'`)
                return
            }

            chat.messages = [];
        },
        enrichMessageText(state, options) {
            const chat = state.chats[options.chat];
            if (!chat) {
                throw new Error(`Failed to enrich message of unknown chat '${chat}'`)
            }

            if (chat.messages.length === 0) {
                throw new Error('There is no messages to enrich')
            }

            chat.messages[chat.messages.length - 1].text += options.enrichment;
        },
        waitForCompletion(state, options) {
            if (!options.chat) {
                throw new Error('Failed to change state of unknown chat');
            }

            state.chats[options.chat].isWaitingForCompletion = true;
        },
        confirmCompletion(state, options) {
            if (!options.chat) {
                throw new Error('Failed to change state of unknown chat');
            }

            state.chats[options.chat].isWaitingForCompletion = false;
        },

    },
    getters: {
        isWaitingForCompletion: state => chatID => {
            if (!chatID || !state.chats[chatID]) {
                return false;
            }

            return state.chats[chatID].isWaitingForCompletion;
        },
        getChatMessages: (state) => (chat) => {
            if (!chat) {
                throw new Error('Failed to get messages of undefined chat');
            }

            if (!state.chats[chat]) {
                return [];
            }

            return state.chats[chat].messages;
        },
    },
});
