export class Role {
    /**
     *
     * @param {String} code
     * @param {String} name
     * @param {String} description
     * @param {String} icon
     */
    constructor(code, name, description, icon) {
        this.code = code;
        this.name = name;
        this.description = description;
        this.icon = icon;
    }
}

export const UserRole = new Role(
    'user',
    'You',
    '',
    'ava-user.svg',
)

export class Message {
    constructor(role, text, gif) {
        this.role = role;
        this.text = text;
        this.gif = gif;
    }
}

export const assistantRoles = {
    'big-joe': new Role(
        'big-joe',
        'Big Joe',
        'A grumpy dog from the south end of Texas.',
        'ava-big-joe.svg',
    ),
    'granny-liz': new Role(
        'granny-liz',
        'Granny Liz',
        'Good old caring grandmother.',
        'ava-granny-liz.svg',
    ),
    'cynologist': new Role(
        'cynologist',
        'Tyler',
        'Outstanding dog trainer with a mass of successful cases in dog training.',
        'cynologist.svg',
    ),
}
